import React from 'react'
import styled from 'styled-components'

export default class Logo extends React.Component {
  render() {
    let houseColor = 'white'
    let textColor = 'white'

    let colorFirst = '#a58a6d'
    let colorSecond = '#b68639'

    const LogoContainer = styled.div`
      .cls-1 {
        fill: #2ca52c;
      }
      .cls-2 {
        fill: #0072ce;
      }
    `

    return (
      <LogoContainer>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 967.1 208.02">
          <defs />
          <title>Cali's Finest Landscaping Logo</title>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                className="cls-1"
                d="M51.44,86.54H73.52v5a29.12,29.12,0,0,1-1.8,10.08,24.15,24.15,0,0,1-5.88,8.94,28.87,28.87,0,0,1-11,6.42,50.53,50.53,0,0,1-16.44,2.4,45.76,45.76,0,0,1-16.56-2.7,28.5,28.5,0,0,1-11-7.2A27.4,27.4,0,0,1,4.88,99.44,37.1,37.1,0,0,1,3.08,88V64a37.1,37.1,0,0,1,1.8-11.46,27.4,27.4,0,0,1,5.88-10.08,28.5,28.5,0,0,1,11-7.2,45.76,45.76,0,0,1,16.56-2.7,50.53,50.53,0,0,1,16.44,2.4,28.87,28.87,0,0,1,11,6.42,24.15,24.15,0,0,1,5.88,8.94,29.12,29.12,0,0,1,1.8,10.08v4H51.44V61.58a8.92,8.92,0,0,0-3-6.84Q45.44,52,38.36,52a18.42,18.42,0,0,0-6.78,1.2A8.62,8.62,0,0,0,27,57.38,14.09,14.09,0,0,0,25.4,64V88a13.66,13.66,0,0,0,1.38,6.3,9.68,9.68,0,0,0,4.2,4.2,15.71,15.71,0,0,0,7.38,1.5q7.08,0,10.08-2.7a8.87,8.87,0,0,0,3-6.9Z"
              />
              <path
                className="cls-1"
                d="M135.2,118l-4.44-13.8H102.44L98,118H75.68L105.8,34h22.8l30.12,84ZM125,86.54,116.48,60l-8.4,26.52Z"
              />
              <path className="cls-1" d="M166,118V34h22.32V98.54h37V118Z" />
              <path className="cls-1" d="M256.87,34v84H234.55V34Z" />
              <path
                className="cls-1"
                d="M293.71,34v5l-9.6,32h-14.4L273.07,34Z"
              />
              <path
                className="cls-1"
                d="M369.55,59.9h-22.2V58.22a6.79,6.79,0,0,0-2.94-5.52q-2.94-2.28-9.54-2.28a21.56,21.56,0,0,0-5.58.72,7.79,7.79,0,0,0-4.14,2.46,5.53,5.53,0,0,0-1.44,3.54,6.86,6.86,0,0,0,.66,2.88,5.3,5.3,0,0,0,2.46,2.4,15.31,15.31,0,0,0,4.56,1.44l15.72,2.88a43.6,43.6,0,0,1,12.66,4.38,22.75,22.75,0,0,1,8.4,7.74,21.21,21.21,0,0,1,3.06,11.64,34.19,34.19,0,0,1-1.8,11.22,22.89,22.89,0,0,1-5.94,9.18,28.75,28.75,0,0,1-11,6.24,52.85,52.85,0,0,1-16.5,2.28q-12.36,0-20.16-3.36T304.21,107a23.43,23.43,0,0,1-3.78-13.26V91.82h22.2v1.44a7,7,0,0,0,3,5.7q3,2.34,9.84,2.34t10-2.22a6.6,6.6,0,0,0,3-5.46,5.92,5.92,0,0,0-1.2-3.72,7.53,7.53,0,0,0-3.54-2.4,31.28,31.28,0,0,0-5-1.32l-15.6-2.88q-11-2-16.08-8.46t-5-15.9a24.31,24.31,0,0,1,1.14-7.26,24.7,24.7,0,0,1,3.42-6.9A27.36,27.36,0,0,1,313,38.54a29.13,29.13,0,0,1,10.2-4.5,51,51,0,0,1,12.3-1.5,48.77,48.77,0,0,1,15.42,2.22,30.72,30.72,0,0,1,10.74,5.82,22.63,22.63,0,0,1,6,8.1,22.91,22.91,0,0,1,1.92,9.06Z"
              />
              <path
                className="cls-1"
                d="M614.71,86.78H581.35V118H559V34h59.28v19.2h-37v14.4h33.36Z"
              />
              <path className="cls-1" d="M652,34v84H629.7V34Z" />
              <path
                className="cls-1"
                d="M720.78,118,688.26,73.7V118H667.14V34h18.48L718.14,78.5V34h21.12v84Z"
              />
              <path
                className="cls-1"
                d="M754.38,118V34h58.8v18.6H776.7V66h32.88V84H776.7V99.38h37.68V118Z"
              />
              <path
                className="cls-1"
                d="M892,59.9h-22.2V58.22a6.79,6.79,0,0,0-2.94-5.52q-2.94-2.28-9.54-2.28a21.56,21.56,0,0,0-5.58.72,7.79,7.79,0,0,0-4.14,2.46,5.53,5.53,0,0,0-1.44,3.54,6.86,6.86,0,0,0,.66,2.88,5.3,5.3,0,0,0,2.46,2.4,15.31,15.31,0,0,0,4.56,1.44l15.72,2.88a43.6,43.6,0,0,1,12.66,4.38,22.75,22.75,0,0,1,8.4,7.74A21.21,21.21,0,0,1,893.7,90.5a34.19,34.19,0,0,1-1.8,11.22A22.89,22.89,0,0,1,886,110.9a28.75,28.75,0,0,1-11,6.24,52.85,52.85,0,0,1-16.5,2.28q-12.36,0-20.16-3.36T826.68,107a23.43,23.43,0,0,1-3.78-13.26V91.82h22.2v1.44a7,7,0,0,0,3,5.7q3,2.34,9.84,2.34t10-2.22a6.6,6.6,0,0,0,3-5.46,5.92,5.92,0,0,0-1.2-3.72,7.53,7.53,0,0,0-3.54-2.4,31.28,31.28,0,0,0-5-1.32l-15.6-2.88q-11-2-16.08-8.46t-5-15.9a24.31,24.31,0,0,1,1.14-7.26,24.7,24.7,0,0,1,3.42-6.9,27.36,27.36,0,0,1,6.42-6.24,29.13,29.13,0,0,1,10.2-4.5,51,51,0,0,1,12.3-1.5,48.77,48.77,0,0,1,15.42,2.22,30.72,30.72,0,0,1,10.74,5.82,22.63,22.63,0,0,1,6,8.1A22.91,22.91,0,0,1,892,57.74Z"
              />
              <path
                className="cls-1"
                d="M967.14,53.42H943.38V118H921.06V53.42H897.42V34h69.72Z"
              />
              <path
                className="cls-1"
                d="M510.86,103.32c-25.69,36.39-58.79,2.91-58.79,2.91-17.59,21.25-40.91,32.09-63.65,26.82-4.73-1.12-3.19-8.33,1.7-7.18,19,4.38,38.41-4.2,53.88-21.48-13.19,1.82-36.83,0-41.84-35.39,35.18-5.65,46,14.33,49.3,25.75a109.9,109.9,0,0,0,11.88-22s-42.62-3.25-37.18-39.23c38.36-5.83,39.58,33.15,39.58,33.15,1.63-4.8,4.62-15.24,4.67-15.48,0,0-26.16-29,.25-51.24,33.67,21.29,6.8,52,6.8,52,0,.51-1.5,7-2.32,9.83,0,0,19.47-23,44.12-7.45-10.53,39.12-49.13,21.45-49.13,21.45a126.77,126.77,0,0,1-11.26,21.42S489.66,76,510.86,103.32Z"
              />
              <path
                className="cls-2"
                d="M0,195.42l8.24-46.9H18.49l-6.76,38H33.57L32,195.42Z"
              />
              <path
                className="cls-2"
                d="M59.3,195.42l.26-3.89A11.68,11.68,0,0,1,55.38,195a12.3,12.3,0,0,1-5.53,1.24,13.81,13.81,0,0,1-5.46-1.07,10.74,10.74,0,0,1-4.16-3.08,11.93,11.93,0,0,1-2.31-4.86,15,15,0,0,1-.07-6.13l1.61-9.38a16.17,16.17,0,0,1,4.83-9.45,13.23,13.23,0,0,1,9.38-3.55,18.58,18.58,0,0,1,3.75.44,12,12,0,0,1,4,1.67,8.83,8.83,0,0,1,3,3l1.27-4.29h8.51l-6.3,35.92Zm-6-7.71a8.42,8.42,0,0,0,3.28-.7,6.26,6.26,0,0,0,2.81-2.48,10.85,10.85,0,0,0,1.54-4.19l.87-5.43a7.1,7.1,0,0,0-.9-5.56q-1.51-2.07-5.53-2.07a7.07,7.07,0,0,0-2.85.63,5.68,5.68,0,0,0-2.44,2.18,9.12,9.12,0,0,0-1.34,3.48l-1.07,6.5q-.68,4,.83,5.8T53.27,187.71Z"
              />
              <path
                className="cls-2"
                d="M88.84,174.51l-3.68,20.91H75.51l6.36-35.92h8.58l-.27,4.23A12.23,12.23,0,0,1,94.67,160a13.3,13.3,0,0,1,5.9-1.31,13.06,13.06,0,0,1,5.26,1,10.37,10.37,0,0,1,4,3,11.12,11.12,0,0,1,2.18,4.72,15.13,15.13,0,0,1,0,6l-3.82,22H98.42l3.55-20q1.41-8.17-5.49-8.17a8.7,8.7,0,0,0-3.28.67,6,6,0,0,0-2.82,2.44A11.06,11.06,0,0,0,88.84,174.51Z"
              />
              <path
                className="cls-2"
                d="M138.49,195.42l.2-3.89a12.43,12.43,0,0,1-4.15,3.42,12,12,0,0,1-5.57,1.27,14.1,14.1,0,0,1-5.49-1.07,11,11,0,0,1-4.22-3.08,11.62,11.62,0,0,1-2.35-4.86,15,15,0,0,1-.06-6.13l1.61-9.38a16.12,16.12,0,0,1,4.85-9.41,13.24,13.24,0,0,1,9.35-3.59,15.65,15.65,0,0,1,6.13,1.17,9.05,9.05,0,0,1,4.19,3.32l3-16.68h9.58L147,195.42Zm-6.23-7.71a6.93,6.93,0,0,0,5.29-2,9.74,9.74,0,0,0,2.41-5.32l.94-4.89q.67-3.89-.87-6.07t-5.7-2.17a5.92,5.92,0,0,0-4.52,1.74,8.5,8.5,0,0,0-2.11,4.55l-1.14,6.5a9.66,9.66,0,0,0,.14,4.29,3.89,3.89,0,0,0,2.07,2.61A7.88,7.88,0,0,0,132.26,187.71Z"
              />
              <path
                className="cls-2"
                d="M187.2,170.09h-9.32l.14-.8a2.67,2.67,0,0,0-1-2.65c-.83-.69-2.32-1-4.46-1a9.88,9.88,0,0,0-2.71.4,4,4,0,0,0-2.11,1.34,3.09,3.09,0,0,0-.74,1.95,2.75,2.75,0,0,0,.67,1.81,5.44,5.44,0,0,0,2.75,1.34l6.3,1.47q8.7,2.07,8.71,9.71a12.91,12.91,0,0,1-.78,4.39,11.17,11.17,0,0,1-2.58,4.06,12.73,12.73,0,0,1-4.95,3,22.34,22.34,0,0,1-7.57,1.14,28.21,28.21,0,0,1-7.41-.84,12,12,0,0,1-4.75-2.31,7.73,7.73,0,0,1-2.35-3.28,8.17,8.17,0,0,1-.37-3.82l.14-1.07h9.38l-.07.94q-.27,3.21,5.76,3.21a11.39,11.39,0,0,0,3-.4,4.19,4.19,0,0,0,2.24-1.37,3.46,3.46,0,0,0,.77-2.18,2.86,2.86,0,0,0-.9-2.31,6.19,6.19,0,0,0-2.58-1.18l-5.76-1.4q-8.79-2.15-8.78-10a9.8,9.8,0,0,1,2-6,13,13,0,0,1,5.3-4.05,19.64,19.64,0,0,1,7.87-1.44,23.11,23.11,0,0,1,8.58,1.34,9.61,9.61,0,0,1,4.72,3.65,7.84,7.84,0,0,1,1,5.46Z"
              />
              <path
                className="cls-2"
                d="M212.92,181.88h9.59l-.2,1.68a14.36,14.36,0,0,1-1.35,4.59,13.85,13.85,0,0,1-3,4.08,13.6,13.6,0,0,1-4.85,2.92,19.87,19.87,0,0,1-6.77,1.07,24.18,24.18,0,0,1-6.8-.94,12.12,12.12,0,0,1-5.36-3.05,11.58,11.58,0,0,1-3-5.19,15.37,15.37,0,0,1-.2-6.9l1.4-8.17A17.06,17.06,0,0,1,195.1,165a14.13,14.13,0,0,1,5.43-4.62,18.59,18.59,0,0,1,8.38-1.71,26.29,26.29,0,0,1,6.73.84,12.62,12.62,0,0,1,5.22,2.64,10,10,0,0,1,2.92,4.39,11.59,11.59,0,0,1,.33,5.73l-.13.81h-9.45l.14-.94a5.19,5.19,0,0,0-.34-2.75,4.15,4.15,0,0,0-1.94-2,7.92,7.92,0,0,0-3.82-.77,7.2,7.2,0,0,0-2.91.63,5.28,5.28,0,0,0-2.38,2.15A9.51,9.51,0,0,0,202,172.7L200.53,181a10.82,10.82,0,0,0,0,2.85,4.19,4.19,0,0,0,1,2.41,5.69,5.69,0,0,0,2,1.44,8.66,8.66,0,0,0,3.39.54,7,7,0,0,0,2.54-.5,4.65,4.65,0,0,0,2.11-1.71,6.85,6.85,0,0,0,1.11-2.55Z"
              />
              <path
                className="cls-2"
                d="M248.9,195.42l.27-3.89A11.71,11.71,0,0,1,245,195a12.23,12.23,0,0,1-5.52,1.24,13.81,13.81,0,0,1-5.46-1.07,10.74,10.74,0,0,1-4.16-3.08,11.93,11.93,0,0,1-2.31-4.86,14.82,14.82,0,0,1-.07-6.13l1.61-9.38a16.17,16.17,0,0,1,4.83-9.45,13.23,13.23,0,0,1,9.37-3.55,18.59,18.59,0,0,1,3.76.44,12,12,0,0,1,4,1.67,8.83,8.83,0,0,1,2.95,3l1.27-4.29h8.51l-6.3,35.92Zm-6-7.71a8.43,8.43,0,0,0,3.29-.7,6.26,6.26,0,0,0,2.81-2.48,11,11,0,0,0,1.54-4.19l.87-5.43a7.14,7.14,0,0,0-.9-5.56q-1.51-2.07-5.53-2.07a7.07,7.07,0,0,0-2.85.63,5.68,5.68,0,0,0-2.44,2.18,9.12,9.12,0,0,0-1.34,3.48l-1.07,6.5q-.67,4,.83,5.8C239.09,187.1,240.68,187.71,242.87,187.71Z"
              />
              <path
                className="cls-2"
                d="M285.89,196.22a15.24,15.24,0,0,1-6.1-1.21,9.74,9.74,0,0,1-4.29-3.28l-2.81,15.75H263l8.44-48h8.58l-.2,4A12,12,0,0,1,284,160a12.1,12.1,0,0,1,5.56-1.27,13.28,13.28,0,0,1,5.23.94,12.89,12.89,0,0,1,3.88,2.51,11.13,11.13,0,0,1,2.72,4.56,14.67,14.67,0,0,1,.37,7.13l-1.68,9.38a16.19,16.19,0,0,1-4.86,9.42A13.19,13.19,0,0,1,285.89,196.22Zm-1.68-8.58a5.91,5.91,0,0,0,4.52-1.74,8.51,8.51,0,0,0,2.12-4.55l1.13-6.5a8.64,8.64,0,0,0-.1-4.26,4,4,0,0,0-2.11-2.58,8,8,0,0,0-3.48-.73q-6.44,0-7.77,7.37l-.87,5.16q-.67,3.62.87,5.72T284.21,187.64Z"
              />
              <path
                className="cls-2"
                d="M336.67,186a12.44,12.44,0,0,1-2.17,5.19,11.64,11.64,0,0,1-4.79,3.65,20,20,0,0,1-8.11,1.41q-8.78,0-12.8-4.12t-2.68-12l1.48-8.1a17.43,17.43,0,0,1,2.61-6.94,13.64,13.64,0,0,1,5.32-4.62,18.4,18.4,0,0,1,8.35-1.71,24,24,0,0,1,6.93,1,11.79,11.79,0,0,1,5.29,3.12,11.45,11.45,0,0,1,2.82,5.19,16.25,16.25,0,0,1,.1,6.8l-.94,5.23H315.7l-.33,2q-1.35,6.9,6.63,6.9a7.84,7.84,0,0,0,3.82-.91,3.8,3.8,0,0,0,2-2.84l.2-1.08h9Zm-19.43-14.61-.4,2.21h13.07l.2-1.13q.52-3.23-1.14-4.9c-1.12-1.11-2.88-1.67-5.3-1.67a6.48,6.48,0,0,0-4.32,1.44A6.34,6.34,0,0,0,317.24,171.36Z"
              />
              <path
                className="cls-2"
                d="M388.26,195.42l-2.08-2.68A22.51,22.51,0,0,1,374,196.22a26.44,26.44,0,0,1-9.75-1.54,13.1,13.1,0,0,1-5.93-4.42,11.85,11.85,0,0,1-2.07-7.1,13.36,13.36,0,0,1,2.44-7.81q2.44-3.53,9.35-6.8a15.35,15.35,0,0,1-2.15-7.71,13.2,13.2,0,0,1,.81-4.42,12.45,12.45,0,0,1,2.58-4.25,13.07,13.07,0,0,1,4.65-3.22,17,17,0,0,1,6.7-1.24,17.9,17.9,0,0,1,6.3,1,10.4,10.4,0,0,1,4.26,2.78,9.72,9.72,0,0,1,2.17,4.08,12.82,12.82,0,0,1,.27,4.93l-.13,1h-8.38l.14-1q.39-3-.94-4.42a5.2,5.2,0,0,0-4-1.48,5.37,5.37,0,0,0-4.12,1.48,5,5,0,0,0-1.38,3.55,13.22,13.22,0,0,0,.31,2.11,11.09,11.09,0,0,0,2.17,4l11,14.47a19.78,19.78,0,0,0,1.21-4.49l.53-3.89h8.38l-.6,4.22a23.7,23.7,0,0,1-1.55,5.9,18.27,18.27,0,0,1-3,4.82l4.69,6.17v2.48Zm-12.86-7a13.4,13.4,0,0,0,6.3-1.54l-9.52-12.39a12.34,12.34,0,0,0-4.66,3.75,7.13,7.13,0,0,0-1.17,3.82,5.72,5.72,0,0,0,2.18,4.52Q370.71,188.39,375.4,188.38Z"
              />
              <path
                className="cls-2"
                d="M439.18,180.61l-7.64-15.34-5.29,30.15h-9.52l8.31-46.9h9.12l9,19.09,15.41-19.09h9.65l-8.31,46.9h-10L455,166.67,443.6,180.61Z"
              />
              <path
                className="cls-2"
                d="M491.17,195.42l.27-3.89a11.71,11.71,0,0,1-4.19,3.45,12.23,12.23,0,0,1-5.52,1.24,13.9,13.9,0,0,1-5.47-1.07,10.71,10.71,0,0,1-4.15-3.08,11.93,11.93,0,0,1-2.31-4.86,14.82,14.82,0,0,1-.07-6.13l1.61-9.38a16.22,16.22,0,0,1,4.82-9.45,13.27,13.27,0,0,1,9.38-3.55,18.59,18.59,0,0,1,3.76.44,12,12,0,0,1,4,1.67,8.79,8.79,0,0,1,2.94,3l1.28-4.29h8.51l-6.3,35.92Zm-6-7.71a8.47,8.47,0,0,0,3.29-.7,6.26,6.26,0,0,0,2.81-2.48,11,11,0,0,0,1.54-4.19l.87-5.43a7.14,7.14,0,0,0-.9-5.56q-1.51-2.07-5.53-2.07a7.07,7.07,0,0,0-2.85.63,5.68,5.68,0,0,0-2.44,2.18,9.12,9.12,0,0,0-1.34,3.48l-1.08,6.5q-.66,4,.84,5.8C481.36,187.1,483,187.71,485.14,187.71Z"
              />
              <path
                className="cls-2"
                d="M517.17,195.42h-9.65l6.3-35.92h9.65Zm7.23-41h-9.71l1.41-7.9h9.71Z"
              />
              <path
                className="cls-2"
                d="M538.21,174.51l-3.69,20.91h-9.65l6.37-35.92h8.57l-.26,4.23A12.2,12.2,0,0,1,544,160a13.33,13.33,0,0,1,5.9-1.31,13.06,13.06,0,0,1,5.26,1,10.37,10.37,0,0,1,3.95,3,11.12,11.12,0,0,1,2.18,4.72,15.13,15.13,0,0,1,0,6l-3.82,22h-9.71l3.55-20q1.39-8.17-5.5-8.17a8.74,8.74,0,0,0-3.28.67,6,6,0,0,0-2.81,2.44A10.89,10.89,0,0,0,538.21,174.51Z"
              />
              <path
                className="cls-2"
                d="M586.51,187.31l-1.41,8.11h-3.61q-7.79,0-11-3t-2-10L571.17,167h-4.69l1.34-7.37h4.69l1.54-9h9.65l-1.61,9h8.58L589.39,167h-8.57L578,182.62c-.31,1.7,0,2.9.81,3.62s2.52,1.07,5,1.07Z"
              />
              <path
                className="cls-2"
                d="M622.16,186a12.45,12.45,0,0,1-2.18,5.19,11.57,11.57,0,0,1-4.79,3.65,20,20,0,0,1-8.11,1.41q-8.77,0-12.8-4.12t-2.68-12l1.48-8.1a17.43,17.43,0,0,1,2.61-6.94,13.67,13.67,0,0,1,5.33-4.62,18.33,18.33,0,0,1,8.34-1.71,24,24,0,0,1,6.93,1,11.88,11.88,0,0,1,5.3,3.12A11.43,11.43,0,0,1,624.4,168a16.09,16.09,0,0,1,.1,6.8l-.94,5.23H601.18l-.33,2q-1.33,6.9,6.63,6.9a7.84,7.84,0,0,0,3.82-.91,3.77,3.77,0,0,0,2-2.84l.2-1.08h9Zm-19.43-14.61-.41,2.21h13.07l.2-1.13q.54-3.23-1.14-4.9t-5.29-1.67a6.48,6.48,0,0,0-4.32,1.44A6.29,6.29,0,0,0,602.73,171.36Z"
              />
              <path
                className="cls-2"
                d="M640.31,174.51l-3.68,20.91H627l6.36-35.92h8.58l-.27,4.23a12.23,12.23,0,0,1,4.49-3.72,13.3,13.3,0,0,1,5.9-1.31,13.06,13.06,0,0,1,5.26,1,10.37,10.37,0,0,1,4,3,11.24,11.24,0,0,1,2.18,4.72,15.13,15.13,0,0,1,0,6l-3.82,22h-9.72l3.55-20q1.41-8.17-5.49-8.17a8.7,8.7,0,0,0-3.28.67,6,6,0,0,0-2.82,2.44A11.06,11.06,0,0,0,640.31,174.51Z"
              />
              <path
                className="cls-2"
                d="M689.76,195.42l.26-3.89a11.68,11.68,0,0,1-4.18,3.45,12.3,12.3,0,0,1-5.53,1.24,13.81,13.81,0,0,1-5.46-1.07,10.74,10.74,0,0,1-4.16-3.08,11.93,11.93,0,0,1-2.31-4.86,14.83,14.83,0,0,1-.06-6.13l1.6-9.38a16.17,16.17,0,0,1,4.83-9.45,13.23,13.23,0,0,1,9.38-3.55,18.58,18.58,0,0,1,3.75.44,12,12,0,0,1,4,1.67,8.83,8.83,0,0,1,3,3l1.27-4.29h8.51l-6.3,35.92Zm-6-7.71A8.42,8.42,0,0,0,687,187a6.21,6.21,0,0,0,2.81-2.48,10.85,10.85,0,0,0,1.54-4.19l.87-5.43a7.06,7.06,0,0,0-.9-5.56c-1-1.38-2.85-2.07-5.53-2.07a7,7,0,0,0-2.84.63,5.65,5.65,0,0,0-2.45,2.18,9.12,9.12,0,0,0-1.34,3.48l-1.07,6.5q-.68,4,.84,5.8T683.73,187.71Z"
              />
              <path
                className="cls-2"
                d="M719.3,174.51l-3.68,20.91H706l6.37-35.92h8.57l-.27,4.23a12.32,12.32,0,0,1,4.49-3.72,13.3,13.3,0,0,1,5.9-1.31,13.06,13.06,0,0,1,5.26,1,10.37,10.37,0,0,1,4,3,11.12,11.12,0,0,1,2.18,4.72,15.13,15.13,0,0,1,0,6l-3.82,22h-9.72l3.56-20q1.39-8.17-5.5-8.17a8.7,8.7,0,0,0-3.28.67,6,6,0,0,0-2.82,2.44A11.06,11.06,0,0,0,719.3,174.51Z"
              />
              <path
                className="cls-2"
                d="M769.35,181.88h9.58l-.2,1.68a14.6,14.6,0,0,1-1.34,4.59,14,14,0,0,1-3,4.08,13.56,13.56,0,0,1-4.86,2.92,19.81,19.81,0,0,1-6.76,1.07,24.3,24.3,0,0,1-6.81-.94,12.17,12.17,0,0,1-5.36-3.05,11.67,11.67,0,0,1-3-5.19,15.51,15.51,0,0,1-.2-6.9l1.41-8.17a17.06,17.06,0,0,1,2.68-6.94,14.13,14.13,0,0,1,5.43-4.62,18.52,18.52,0,0,1,8.37-1.71,26.24,26.24,0,0,1,6.73.84,12.6,12.6,0,0,1,5.23,2.64,10.21,10.21,0,0,1,2.92,4.39,11.59,11.59,0,0,1,.33,5.73l-.13.81H771l.13-.94a5.09,5.09,0,0,0-.33-2.75,4.17,4.17,0,0,0-2-2,7.87,7.87,0,0,0-3.81-.77,7.21,7.21,0,0,0-2.92.63,5.38,5.38,0,0,0-2.38,2.15,9.5,9.5,0,0,0-1.27,3.31L757,181a10.81,10.81,0,0,0,0,2.85,4.26,4.26,0,0,0,1,2.41,5.82,5.82,0,0,0,2.05,1.44,8.61,8.61,0,0,0,3.38.54,7,7,0,0,0,2.55-.5A4.65,4.65,0,0,0,768,186a6.82,6.82,0,0,0,1.1-2.55Z"
              />
              <path
                className="cls-2"
                d="M814.58,186a12.45,12.45,0,0,1-2.18,5.19,11.57,11.57,0,0,1-4.79,3.65,20,20,0,0,1-8.11,1.41q-8.78,0-12.8-4.12t-2.68-12l1.48-8.1a17.43,17.43,0,0,1,2.61-6.94,13.67,13.67,0,0,1,5.33-4.62,18.33,18.33,0,0,1,8.34-1.71,24,24,0,0,1,6.93,1,11.88,11.88,0,0,1,5.3,3.12,11.43,11.43,0,0,1,2.81,5.19,16.09,16.09,0,0,1,.1,6.8L816,180H793.6l-.33,2q-1.34,6.9,6.63,6.9a7.84,7.84,0,0,0,3.82-.91,3.77,3.77,0,0,0,2-2.84l.2-1.08h9Zm-19.43-14.61-.41,2.21h13.07l.2-1.13q.54-3.23-1.14-4.9t-5.29-1.67a6.48,6.48,0,0,0-4.32,1.44A6.29,6.29,0,0,0,795.15,171.36Z"
              />
              <path
                className="cls-2"
                d="M831.06,185.9v2.48l-8,16.75h-7l5.43-19.23Z"
              />
              <path
                className="cls-2"
                d="M869.92,148.52l-8.25,46.9H851.49l8.24-46.9Z"
              />
              <path
                className="cls-2"
                d="M883.45,174.51l-3.69,20.91h-9.64l6.36-35.92h8.58l-.27,4.23a12.15,12.15,0,0,1,4.49-3.72,13.25,13.25,0,0,1,5.89-1.31,13,13,0,0,1,5.26,1,10.33,10.33,0,0,1,4,3,11.11,11.11,0,0,1,2.17,4.72,14.77,14.77,0,0,1,0,6l-3.82,22H893l3.55-20q1.41-8.17-5.49-8.17a8.75,8.75,0,0,0-3.29.67,6,6,0,0,0-2.81,2.44A11.06,11.06,0,0,0,883.45,174.51Z"
              />
              <path
                className="cls-2"
                d="M933.5,181.88h9.58l-.2,1.68a14.6,14.6,0,0,1-1.34,4.59,14.21,14.21,0,0,1-3,4.08,13.73,13.73,0,0,1-4.86,2.92,19.87,19.87,0,0,1-6.77,1.07,24.23,24.23,0,0,1-6.8-.94,12.12,12.12,0,0,1-5.36-3.05,11.58,11.58,0,0,1-3-5.19,15.37,15.37,0,0,1-.2-6.9L913,172a16.93,16.93,0,0,1,2.69-6.94,14.1,14.1,0,0,1,5.42-4.62,18.59,18.59,0,0,1,8.38-1.71,26.29,26.29,0,0,1,6.73.84,12.72,12.72,0,0,1,5.23,2.64,10.1,10.1,0,0,1,2.91,4.39,11.71,11.71,0,0,1,.34,5.73l-.14.81H935.1l.14-.94a5.19,5.19,0,0,0-.34-2.75,4.11,4.11,0,0,0-1.94-2,7.92,7.92,0,0,0-3.82-.77,7.2,7.2,0,0,0-2.91.63,5.33,5.33,0,0,0-2.38,2.15,9.3,9.3,0,0,0-1.27,3.31L921.1,181a11.27,11.27,0,0,0,0,2.85,4.19,4.19,0,0,0,1,2.41,5.88,5.88,0,0,0,2,1.44,8.7,8.7,0,0,0,3.39.54,7,7,0,0,0,2.54-.5,4.65,4.65,0,0,0,2.11-1.71,6.85,6.85,0,0,0,1.11-2.55Z"
              />
              <path className="cls-2" d="M956.54,195.42h-10l1.61-9.52h10.05Z" />
            </g>
          </g>
        </svg>
      </LogoContainer>
    )
  }
}
