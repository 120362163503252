import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { Check } from 'react-feather'
import Logo from '../components/logo-svg'
import YelpIcon from '../assets/icons/yelp'
import FacebookIcon from '../assets/icons/facebook'
import InstagramIcon from '../assets/icons/instagram'
import GoogleIcon from '../assets/icons/google'
import YoutubeIcon from '../assets/icons/youtube'

const FooterOuterContainer = styled.div`
  background: #fff;
  padding: 4em 0;
  font-size: 0.9em;
`
const LegalLinks = styled.div`
  margin-top: 5px;
  a {
    color: #777;
    margin-right: 10px;
    font-size: 0.9em;
    &:hover {
      color: #222;
    }
  }
`
const CopyrightText = styled.p`
  color: #757575;
  font-size: 0.9em;
  margin: 0;
  padding: 0.5em;
`
const SidebarHeader = styled.h4`
  margin-bottom: 0.5em;
`
const SidebarP = styled.p`
  color: #444;
`
const FooterLinksMenu = styled.ul``

const WhyUsUnorderedList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: relative;
  li {
    list-style-type: none;
    padding: 0;
    padding-left: 25px;
    line-height: 1.4;
    position: relative;
    svg {
      border-radius: 50%;
      padding-top: 5px;
      stroke-width: 5px;
      margin-left: -30px;
      padding: 4px;
      position: absolute;
      top: 2px;
    }
  }
`

const SocialMediaIcon = styled.div`
  padding: 0.5em 1em;
  path {
    fill: #444;
  }
  a {
    svg {
      height: 24px;
    }
  }
`

const SocialMediaIconsContainer = styled.div`
  display: flex;
  @media (max-width: 767px) {
    justify-content: center;
    font-size: 0.9em;
    margin-top: 1em;
  }
`
const LogoFooterContainer = styled.div`
  width: 210px;
  margin-bottom: 1em;
  svg .cls-1,
  svg .cls-2 {
    fill: #999;
  }
  @media (max-width: 767px) {
    text-align: center;
    margin: 0 auto;
    margin-bottom: 1em;
  }
`
const CopyrightSocialMedia = styled.div`
  border-top: 1px solid #e8e8e8;
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 767px) {
    display: block;
    text-align: center;
  }
`

const SocialMediaIcons = siteMetadata => {
  return (
    <SocialMediaIconsContainer className="d-flex">
      {' '}
      {siteMetadata.siteMetadata.social.yelp ? (
        <SocialMediaIcon>
          <a href={siteMetadata.siteMetadata.social.yelp} target="_blank">
            <YelpIcon />
          </a>
        </SocialMediaIcon>
      ) : (
        ''
      )}
      {siteMetadata.siteMetadata.social.facebook ? (
        <SocialMediaIcon>
          <a href={siteMetadata.siteMetadata.social.facebook} target="_blank">
            <FacebookIcon />
          </a>
        </SocialMediaIcon>
      ) : (
        ''
      )}
      {siteMetadata.siteMetadata.social.google ? (
        <SocialMediaIcon>
          <a href={siteMetadata.siteMetadata.social.google} target="_blank">
            <GoogleIcon />
          </a>
        </SocialMediaIcon>
      ) : (
        ''
      )}
      {siteMetadata.siteMetadata.social.youtube ? (
        <SocialMediaIcon>
          <a href={siteMetadata.siteMetadata.social.youtube} target="_blank">
            <YoutubeIcon />
          </a>
        </SocialMediaIcon>
      ) : (
        ''
      )}
      {siteMetadata.siteMetadata.social.instagram ? (
        <SocialMediaIcon>
          <a href={siteMetadata.siteMetadata.social.instagram} target="_blank">
            <InstagramIcon />
          </a>
        </SocialMediaIcon>
      ) : (
        ''
      )}
    </SocialMediaIconsContainer>
  )
}

const CompanyInfoColumn = props => {
  let { siteMetadata } = props
  return (
    <>
      <LogoFooterContainer>
        <Logo />
      </LogoFooterContainer>

      <div className="row">
        <div className="col-6 col-md-12">
          <SidebarHeader>Licensed &amp; Insured</SidebarHeader>
          <SidebarP>CSLB License: #{siteMetadata.companyInfo.license}</SidebarP>
        </div>
        <div className="col-6 col-md-12">
          <SidebarHeader>Contact Us</SidebarHeader>
          <SidebarP>
            {siteMetadata.companyInfo.phone}
            <br />
            {siteMetadata.companyInfo.email}
          </SidebarP>
        </div>
      </div>
    </>
  )
}

const WhyUs = props => {
  return (
    <>
      <h4>Why Us</h4>
      <WhyUsUnorderedList>
        {props.siteMetadata.whyUs.map((item, index) => (
          <li key={index}>
            <Check size={22} className="text-color-primary" />
            {item}
          </li>
        ))}
      </WhyUsUnorderedList>
    </>
  )
}

const FooterColumms = footerData => {
  const footerColumns = footerData.data.map((footer, index) => (
    <FooterColumm
      key={index}
      footerInfo={footer}
      columnsCount={footerData.data.length}
      siteData={footerData.siteData.site}
    />
  ))
  return footerColumns
}

const FooterColumm = footerInfo => {
  if (
    footerInfo.footerInfo.node &&
    footerInfo.footerInfo.node.html &&
    footerInfo.footerInfo.node.html.html.includes('{{CompanyInfo}}')
  ) {
    return (
      <div
        className={'mb-4 col-12 col-md-' + 12 / footerInfo.columnsCount}
        style={{
          order:
            footerInfo.footerInfo &&
            footerInfo.footerInfo.node &&
            footerInfo.footerInfo.node.order,
        }}
      >
        <CompanyInfoColumn siteMetadata={footerInfo.siteData.siteMetadata} />
      </div>
    )
  }
  if (
    footerInfo.footerInfo.node &&
    footerInfo.footerInfo.node.html &&
    footerInfo.footerInfo.node.html.html.includes('{{WhyUs}}')
  ) {
    return (
      <div
        className={'mb-4 col-12 col-md-' + 12 / footerInfo.columnsCount}
        style={{
          order:
            footerInfo.footerInfo &&
            footerInfo.footerInfo.node &&
            footerInfo.footerInfo.node.order,
        }}
      >
        <WhyUs siteMetadata={footerInfo.siteData.siteMetadata} />
      </div>
    )
  }

  if (footerInfo.footerInfo.node.menuLinks) {
    const footerLinksMapped = footerInfo.footerInfo.node.menuLinks.map(
      (menuItem, index) => (
        <li key={index}>
          <Link
            to={menuItem.url ? menuItem.url : '#'}
            title={menuItem.title}
            activeClassName={'active'}
          >
            {menuItem.title}
          </Link>
        </li>
      )
    )
    return (
      <div
        className={'mb-4 col-12 col-md-' + 12 / footerInfo.columnsCount}
        style={{
          order:
            footerInfo.footerInfo &&
            footerInfo.footerInfo.node &&
            footerInfo.footerInfo.node.order,
        }}
      >
        <h4>{footerInfo.footerInfo.node.title}</h4>
        <FooterLinksMenu>{footerLinksMapped}</FooterLinksMenu>
      </div>
    )
  }

  return (
    <div
      className={'mb-4 col-12 col-md-' + 12 / footerInfo.columnsCount}
      style={{
        order:
          footerInfo.footerInfo &&
          footerInfo.footerInfo.node &&
          footerInfo.footerInfo.node.order,
      }}
    >
      <h4>{footerInfo.footerInfo.node.title}</h4>
      {footerInfo.footerInfo.node &&
      footerInfo.footerInfo.node.html &&
      footerInfo.footerInfo.node.html.html ? (
        <div
          dangerouslySetInnerHTML={{
            __html: footerInfo.footerInfo.node.html.html,
          }}
        />
      ) : (
        ''
      )}
    </div>
  )
}

const Footer = () => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        site {
          siteMetadata {
            title
            whyUs
            companyInfo {
              phone
              email
              addressLine1
              addressLine2
              city
              state
              zip
              license
            }
            social {
              yelp
              facebook
              instagram
              youtube
              google
            }
          }
        }
        allContentfulFooterFooterColumn {
          edges {
            node {
              id
              title
              order
              menuLinks {
                id
                name
                title
                url
                openInNewWindow
                submenu {
                  id
                  name
                  menuItems {
                    id
                    name
                    title
                    url
                    openInNewWindow
                  }
                }
              }
              html {
                html
              }
            }
          }
        }
      }
    `}
    render={function(data) {
      //assuming length of array equals columns intended
      let footerData = data.allContentfulFooterFooterColumn.edges

      let { siteMetadata } = data.site

      return (
        <FooterOuterContainer className="container-fluid">
          <div className="container">
            <div className="row">
              <FooterColumms data={footerData} siteData={data} />
            </div>
          </div>
          <div className="container">
            <CopyrightSocialMedia>
              <CopyrightText>
                &copy; {data.site.siteMetadata.title} {new Date().getFullYear()}
                . All Rights Reserved.
                <br />
                CSLB License: {'#' + siteMetadata.companyInfo.license + ' '}
                <span
                  className="rating-desc"
                  itemScope=""
                  itemType="http://schema.org/Product"
                >
                  <span itemProp="name">{data.site.siteMetadata.title}</span>
                  <span
                    itemProp="aggregateRating"
                    itemScope=""
                    itemType="http://schema.org/AggregateRating"
                  >
                    {' '}
                    rated <span itemProp="ratingValue">4.9</span> / 5 based on{' '}
                    <span itemProp="reviewCount">79</span> reviews.
                  </span>
                </span>
                <br />
                <LegalLinks>
                  <Link
                    to={'/privacy-policy'}
                    title={'Learn about our commitment to privacy'}
                  >
                    Privacy Policy
                  </Link>
                  <Link to={'/contact'} title={'Contact Us'}>
                    Contact Us
                  </Link>
                </LegalLinks>
              </CopyrightText>

              <SocialMediaIcons siteMetadata={siteMetadata} />
            </CopyrightSocialMedia>
          </div>
        </FooterOuterContainer>
      )
    }}
  />
)

export default Footer
