import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Header from './header'
import Footer from './footer'
import './layout.css'
import '../fonts/fonts.css'
import '../styles/index.scss'
import '../styles/custom.scss'

const LayoutContainer = styled.div``

const Layout = ({ children, transparentHeader }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            description
            companyInfo {
              phone
              email
              addressLine1
              addressLine2
              city
              state
              zip
              license
            }
          }
        }
        allContentfulNavigation(filter: { name: { eq: "Main Navigation" } }) {
          edges {
            node {
              id
              name
              menuItems {
                id
                name
                title
                url
                openInNewWindow
                submenu {
                  id
                  name
                  menuItems {
                    id
                    name
                    title
                    url
                    openInNewWindow
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      console.log(transparentHeader)
      return (
        <>
          <Header
            transparentHeader={transparentHeader}
            siteTitle={data.site.siteMetadata.title}
            siteDescription={data.site.siteMetadata.description}
            companyInfo={data.site.siteMetadata.companyInfo}
            mainNavigation={
              data.allContentfulNavigation.edges[0].node.menuItems
            }
          />

          <LayoutContainer
            className={transparentHeader ? 'transparent-header' : null}
          >
            {children}
          </LayoutContainer>
          <Footer />
        </>
      )
    }}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
