import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

class Button extends React.Component {
  render() {
    const ButtonOuter = styled.button`
      padding: 0.5em 1.5em;
      font-size: inherit;
      color: white;
      display: inline-block;
      text-align: center;
      line-height: inherit;
      font-weight: 600;
      margin: 0px;
      text-decoration: none;
      border-width: 0px;
      border-style: initial;
      border-color: initial;
      border-image: initial;
      border-radius: 30px;
      cursor: pointer;
      svg {
        width: 30px;
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        transition: transform 0.25s ease;
      }
      .buttonContent {
        transition: transform 0.25s ease;
      }
      display: inline-flex;
      align-items: center;
      justify-content: center;
      &:hover {
        svg {
          transform: ${this.props.hasArrow
            ? 'translateX(5px) rotate(-90deg)'
            : ''};
        }
        .buttonContent {
          transform: ${this.props.hasArrow ? 'translateX(-5px)' : ''};
        }
      }
    `

    const ButtonOuterLink = styled(Link)`
      padding: 0.5em 1.5em;
      font-size: inherit;
      color: white;
      display: inline-block;
      text-align: center;
      line-height: inherit;
      font-weight: 600;
      margin: 0px;
      text-decoration: none;
      border-width: 0px;
      border-style: initial;
      border-color: initial;
      border-image: initial;
      border-radius: 30px;
      display: inline-flex;
      align-items: center;
      svg {
        width: 30px;
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        transition: transform 0.25s ease;
      }
      .buttonContent {
        transition: transform 0.25s ease;
      }
      display: inline-flex;
      align-items: center;
      justify-content: center;
      &:hover {
        svg {
          transform: ${this.props.hasArrow
            ? 'translateX(5px) rotate(-90deg)'
            : ''};
        }
        .buttonContent {
          transform: ${this.props.hasArrow ? 'translateX(-5px)' : ''};
        }
      }
    `
    return (
      <>
        {this.props.to ? (
          <ButtonOuterLink
            title={
              this.props && this.props.title
                ? this.props.title
                : this.props.children
            }
            to={this.props.to}
            className={
              'button-style-primary ' +
              'button-style-' +
              this.props.color +
              (this.props.className ? ' ' + this.props.className : '')
            }
          >
            {this.props.children ? (
              <>
                <span className="buttonContent">{this.props.children}</span>
                {this.props.hasArrow ? (
                  <svg
                    className="Button--button__arrowIcon--ny3GQ"
                    width="12"
                    height="14"
                    viewBox="0 0 12 14"
                  >
                    <path
                      fill="currentColor"
                      d="M5.17 10V.17h2V10l3.24-3.24 1.42 1.41-5.66 5.66-1.41-1.42L.51 8.17l1.42-1.41z"
                    />
                  </svg>
                ) : null}
              </>
            ) : (
              '@TODO Add Button Content'
            )}
          </ButtonOuterLink>
        ) : (
          <ButtonOuter
            role={this.props.role}
            type={this.props.type}
            className={
              'button-style-primary ' +
              'button-style-' +
              this.props.color +
              (this.props.className ? ' ' + this.props.className : '')
            }
          >
            {this.props.children ? (
              <>
                <span className="buttonContent">{this.props.children}</span>
                {this.props.hasArrow ? (
                  <svg
                    class="Button--button__arrowIcon--ny3GQ"
                    width="12"
                    height="14"
                    viewBox="0 0 12 14"
                  >
                    <path
                      fill="currentColor"
                      d="M5.17 10V.17h2V10l3.24-3.24 1.42 1.41-5.66 5.66-1.41-1.42L.51 8.17l1.42-1.41z"
                    />
                  </svg>
                ) : null}
              </>
            ) : (
              '@TODO Add Button Content'
            )}
          </ButtonOuter>
        )}
      </>
    )
  }
}

export default Button
