import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import styles from '../styles/variables.scss'

import Logo from '../components/logo-svg'
import Button from '../components/button'
import { Menu, X } from 'react-feather'

const MenuLi = styled.li`
  position: relative;
  @media (min-width: 992px) {
    &.has-submenu {
      a {
        &:after {
          border-style: solid;
          border-width: 0.15em 0.15em 0 0;
          content: '';
          display: inline-block;
          height: 0.5em;
          left: 0.15em;
          position: relative;
          top: 0.35em;
          transform: rotate(135deg);
          vertical-align: top;
          width: 0.5em;
          margin-left: 5px;
          opacity: 0.6;
        }
      }

      &:hover {
        .submenu {
          display: inline-block !important;
          li {
            padding: 0;
            a {
              &:after {
                content: none;
                border: none;
              }
              color: white;
              width: 100%;
              padding: 15px 20px;
            }
          }
        }
      }
    }
  }
`

const SubMenu = styled.ul`
  display: block;
  position: inherit;
  box-shadow: none;

  li {
    @media (max-width: 992px) {
      background: transparent !important;
    }
  }
  a {
    @media (max-width: 992px) {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 992px) {
    background: transparent !important;
  }

  @media (min-width: 992px) {
    display: none !important;
    box-shadow: 1px 4px 7px 0px hsla(0, 0%, 0%, 0.15);
    position: absolute;
    border-radius: 0 0 4px 4px;
  }

  font-size: 0.7937rem;
  line-height: 1.45rem;
  left: 0px;
  top: 100%;
  color: rgba(0, 0, 0, 0.88);
  list-style: none;
  margin: 0px;
  padding: 0.725rem 0px;
  z-index: 100;
  overflow: hidden;

  @media (max-width: 992px) {
    background: transparent !important;
  }
`

const MainNavigation = ({ mainNavigation }) => {
  const menuItems = mainNavigation.map((menuItem, index) => (
    <MenuLi
      key={index}
      className={
        (menuItem.submenu ? 'has-submenu ' : '') +
        (menuItem && menuItem.cssClasses ? menuItem.cssClasses : '')
      }
    >
      <Link
        to={'/' + menuItem.url ? menuItem.url : '#'}
        title={menuItem.title}
        activeClassName={'active'}
      >
        {menuItem.name}
      </Link>

      {/* render submenu */}
      {menuItem && menuItem.submenu ? (
        <SubMenu className="submenu bg-color-primary-lg">
          <MainNavigation mainNavigation={menuItem.submenu[0].menuItems} />
        </SubMenu>
      ) : (
        ''
      )}
    </MenuLi>
  ))
  return menuItems
}

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.toggleMobileMenuOverlay = this.toggleMobileMenuOverlay.bind(this)
    this.state = { headerOpen: false }
  }

  componentDidMount() {
    this.updatePhoneLoadScrollReveal()
  }
  componentDidUpdate() {
    this.updatePhoneLoadScrollReveal()
  }

  updatePhoneLoadScrollReveal = () => {
    // window.ScrollReveal().reveal('.hero-title, .hero-paragraph, .hero-cta', {
    //   duration: 1e3,
    //   distance: '40px',
    //   easing: 'cubic-bezier(0.5, -0.01, 0, 1.005)',
    //   origin: 'bottom',
    //   interval: 150,
    // })
    // window.ScrollReveal().reveal('.feature, .pricing-table', {
    //   duration: 600,
    //   distance: '40px',
    //   easing: 'cubic-bezier(0.5, -0.01, 0, 1.005)',
    //   interval: 100,
    //   origin: 'bottom',
    //   viewFactor: 0.5,
    // })
    // window.ScrollReveal().reveal('.feature-extended-image', {
    //   duration: 600,
    //   scale: 0.9,
    //   interval: 100,
    //   easing: 'cubic-bezier(0.5, -0.01, 0, 1.005)',
    //   viewFactor: 0.5,
    // })
  }

  toggleMobileMenuOverlay = () => {
    this.setState({
      headerOpen: (this.headerOpen = !this.headerOpen),
    })

    if (document.body.classList.contains('mobile-menu-overlay--open')) {
      document.body.classList.remove('mobile-menu-overlay--open')
    } else {
      document.body.classList.add('mobile-menu-overlay--open')
    }
  }

  render() {
    const LogoSVG = styled.div`
      width: 210px;
    `

    const PhoneNumberHeader = styled.a`
      text-decoration: none;
      font-weight: 600;
      font-size: 1.2em;
      margin-right: 1em;

      color: #fff !important;

      @media (max-width: 992px) {
        color: #fff !important;
        font-size: 1.2m;
      }
    `
    const HeaderOuterContainer = styled.div`
      background: #fff;
      z-index: 105;
      position: relative;
      padding: 0 15px !important;
      box-shadow: rgba(0, 0, 0, 0.03) 0px 2px 4px 0px;

      @media (max-width: 992px) {
        padding: 6px 15px !important;
      }
    `

    const NavOuterContainer = styled.nav`
      ul {
        margin: 0;
        padding: 0;
        display: flex;
        cursor: pointer;
        &:hover {
          li {
            cursor: pointer;
            a {
              opacity: 0.8;
            }
            &:hover {
              a {
                opacity: 1;
              }
            }
          }
        }
        li {
          list-style-type: none;
          margin: 0;
          padding: 0 18px;
          cursor: pointer;
          &:first-child {
            padding-left: 0;
          }
          a {
            cursor: pointer;
            color: #333;
            text-decoration: none;
            padding: 30px 0;
            padding-bottom: 26px;
            transition: 0.2s ease all;
            font-size: 17px;
            font-weight: 600;
            display: inline-block;
            border-bottom: 4px solid transparent;
            &:hover {
              opacity: 1;
            }
          }
        }
      }
    `
    const NavigationMobileUL = styled.ul`
      list-style-type: none;
      margin: 0;
      padding: 1em !important;

      li {
        font-weight: 600;
        a {
          color: #333;
          text-decoration: none;
          font-size: 1.15em;
        }
        &.active {
          color: green;
        }
      }
    `
    const MobileSecondaryCTA = styled.div`
      box-shadow: 0 3px 10px 2px hsla(0, 0%, 0%, 0.05);
      background: white;

      padding: 0.5em 0;
    `
    const StickyContainer = styled.div`
      z-index: 1000;
      position: sticky;
      top: 0;
    `

    const LogoContainer = styled.div`
      a {
        text-decoration: none;
        font-size: 1.25em;
        font-weight: 600;
        color: ${styles.brandPrimary};
        padding: 0.5em 0;
      }
    `

    const MenuMobile = styled.div``

    const MobileIconBurger = styled.button`
      background: none;
      border: none;
      line-height: 0;
      cursor: pointer;
      outline: none;
      &:hover {
        opacity: 0.8;
      }
    `
    const CTASectionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
}
`
    const SiteTagline = styled.div`
      align-items: center;
      justify-content: center;
      p {
        margin-bottom: 0;
        color: #fff;
        font-size: 0.85em;
      }
    `
    const HeaderSpotlight = styled.div`
      @media (min-width: 992px) {
        padding: 10px;
      }
    `

    const CallToAction = () => {
      return (
        <CTASectionWrapper>
          <PhoneNumberHeader
            className="camber"
            href={'tel:' + this.props.companyInfo.phone}
          >
            {this.props.companyInfo.phone}
          </PhoneNumberHeader>
          <Button to={'/contact/'} color={'white'}>
            Get FREE Quote
          </Button>
        </CTASectionWrapper>
      )
    }

    return (
      <>
        <HeaderSpotlight className="bg-color-primary">
          <div className="container d-flex">
            {this.props.siteDescription ? (
              <SiteTagline className="d-none d-lg-flex col-lg-6 text-color-white justify-content-start">
                <p className="camber">
                  {this.props && this.props.siteDescription}
                </p>
              </SiteTagline>
            ) : null}

            <div className="d-none d-lg-flex align-items-center col-lg-6 justify-content-end">
              <CallToAction />
            </div>
          </div>
        </HeaderSpotlight>
        <HeaderOuterContainer
          className={
            'container-fluid' +
            (this.props && this.props.transparentHeader
              ? ' is-transparent'
              : '')
          }
        >
          <div className="container padding-0">
            <div className="row">
              <LogoContainer className="col-6 col-lg-3 d-flex align-items-center">
                <Link to="/" style={{ lineHeight: '0' }}>
                  <LogoSVG alt={this.props.siteTitle + ' Logo'}>
                    <Logo />
                  </LogoSVG>
                </Link>
              </LogoContainer>

              <NavOuterContainer className="camber menu-desktop d-none d-lg-flex col-6 col-lg-9 align-items-center  justify-content-end">
                <ul>
                  <MainNavigation mainNavigation={this.props.mainNavigation} />
                </ul>
              </NavOuterContainer>

              <MenuMobile className="camber d-lg-none d-xl-none col-6 d-flex align-items-center justify-content-end">
                <MobileIconBurger onClick={this.toggleMobileMenuOverlay}>
                  {this.state.headerOpen ? (
                    <>
                      <X size={30} />
                      <span className="sr-only">Close Menu</span>
                    </>
                  ) : (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <line x1="5" y1="12" x2="23" y2="12" />
                        <line x1="2" y1="6" x2="23" y2="6" />
                        <line x1="8" y1="18" x2="23" y2="18" />
                      </svg>
                      <span className="sr-only">Open Menu</span>
                    </>
                  )}
                </MobileIconBurger>
              </MenuMobile>
            </div>
          </div>
        </HeaderOuterContainer>

        <StickyContainer>
          <MobileSecondaryCTA className="d-lg-none d-xl-none bg-color-primary text-color-white">
            <div className="container">
              <CallToAction className="d-lg-none d-xl-none col-6 d-flex align-items-center" />
            </div>
          </MobileSecondaryCTA>
        </StickyContainer>
        <div
          className={'mobileOverlay' + (this.state.headerOpen ? ' open' : '')}
        >
          <div className="animate-overlay-content">
            <NavigationMobileUL className="camber">
              <MainNavigation mainNavigation={this.props.mainNavigation} />
            </NavigationMobileUL>
          </div>
        </div>
      </>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  siteDescription: PropTypes.string,
  mainNavigation: PropTypes.array,
}

Header.defaultProps = {
  siteTitle: '',
  siteDescription: '',
  mainNavigation: '',
}

export default Header
